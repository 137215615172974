<template>
  <section id="sus-manage-guides" class="wh-container">
    <div class="nav">
      <Breadcrumbs
        :options="[
          {
            label: 'Faturamento TISS',
            onClick: () => this.$router.push('/faturamento-sus/dashboard')
          },
          {
            label: 'Lotes enviados',
            onClick: () => this.$router.push('/faturamento-sus/lotes-enviados')
          },
          {
            label: 'Gerenciar guias',
            onClick: () =>
              this.$router.push({
                name: 'sus-manage-guides',
                params: { batchId: $route.params.batchId }
              })
          },
          {
            label: 'Lote faturado'
          }
        ]"
      />
      <b-button variant="primary" @click="bulkUpdateSusGuides" :disabled="canUpdateSusGuides">
        Salvar
      </b-button>
    </div>

    <b-container class="guides-content" fluid>
      <GuidesHeader
        type="batch"
        :id="$route.params.batchId"
        :loading="loading" 
        :disabledStatus="selectedSusGuides.length === 0"
        @update="updateStatuses" 
        @reload="getGuides"
      />
      <GuidesFilter v-model="filters" />
      <GuidesTable
        :tissGuides="tissGuides"
        :selectedSusGuides="selectedSusGuides"
        :handleSelectRow="handleSelectRow"
        :selectAll="selectAll"
        :unSelectAll="unSelectAll"
      />
      <ManageGuidesSidebar
        :canUpdateSusGuides="canUpdateSusGuides"
        @update="updateGuides" 
        @save="bulkUpdateSusGuides"
      />
    </b-container>
  </section>
</template>

<script>
import api from '@/modules/sus/manageGuides/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { SusGuide } from '@/modules/sus/manageGuides/utils/statuses'
import { onChangeSituation } from '@/modules/sus/manageGuides/utils/guideHelper'

export default {
  name: 'manage-guides',
  components: {
    Breadcrumbs: () => import('@/components/General/Breadcrumbs'),
    GuidesHeader: () => import('@/modules/sus/manageGuides/components/GuidesHeader'),
    GuidesFilter: () => import('@/modules/sus/manageGuides/components/GuidesFilter'),
    GuidesTable: () => import('@/modules/sus/manageGuides/components/GuidesTable'),
    ManageGuidesSidebar: () => import('@/modules/sus/manageGuides/sidebar/ManageGuidesSidebar'),
  },
  data() {
    return {
      loading: false,
      clinic: getCurrentClinic(),
      susGuides: [],
      selectedSusGuides: [],
      filters: {},
    }
  },
  async mounted() {
    await this.getGuides()
    this.$root.$emit('bv::toggle::collapse', 'manage-guides-sidebar')
  },
  computed: {
    canUpdateSusGuides() {
      const validSituations = [
        SusGuide.GUIDE_SITUATION_OPEN,
        SusGuide.GUIDE_SITUATION_PAYED,
        SusGuide.GUIDE_SITUATION_PARTIALLY_PAID,
        SusGuide.GUIDE_SITUATION_GLOSSED,
      ]
      return this.susGuides.some(item => !validSituations.includes(item.situation))
    }
  },
  methods: {
    async getGuides() {
      this.loading = true
      try {
        const { data } = await api.getSusGuideByBatchId(this.$route.params.batchId, this.filters)
        this.susGuides = data
      } catch (error) {
        console.log(error);
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    handleSelectRow(checked, susGuide) {
      if (!checked) {
        this.selectedSusGuides = this.selectedSusGuides.filter(susGuideId => susGuideId !== susGuide.id)
        return
      }
      if (this.selectedSusGuides.includes(susGuide.id)) return
      this.selectedSusGuides.push(susGuide.id)
    },
    selectAll() {
      this.selectedSusGuides = this.susGuides.map(susGuide => susGuide.id)
    },
    unSelectAll() {
      this.selectedSusGuides = []
    },
    updateStatuses(situation) {
      this.susGuides.forEach((susGuide) => {
        if (this.selectedSusGuides.includes(susGuide.id)) {
          susGuide.situation = situation
          onChangeSituation(susGuide, situation)
        }
      })
    },
    async bulkUpdateSusGuides() {

      if(this.susGuides.some(guide => guide.situation === SusGuide.GUIDE_SITUATION_PARTIALLY_PAID && !guide.items_paid)) {
        this.$toast.warning('Não é possível atualizar guias com situação "Pago parcial" sem informar os valores recebidos')
        return
      }

      this.loading = true
      try {
        const data = this.susGuides.filter(guide => guide.updatedStatus).map((item) => ({
            id: item.id,
            situation: item.situation,
            observations: item.observations
        }))
        await api.bulkUpdateSusGuides(data)
        this.$toast.success('Guias atualizadas com sucesso')
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        this.loading = false
      }
    },
    updateGuides(situation) {
      this.susGuides.forEach((susGuide) => {
          susGuide.situation = situation
          onChangeSituation(susGuide, situation)
      })
    },
  },
  watch: {
    filters: {
      async handler() { 
        await this.getGuides()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
#sus-manage-guides {
  padding: 25px;

  .wh-container {
    height: 100%;
  }

  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .breadcrumbs-wrapper {
    width: inherit;
    padding: 0;
  }

  .guides-content {
    margin-top: 20px;
    padding: 20px 25px;
    border-radius: 8px;
    background-color: var(--neutral-000);
  }

  .filters {
    display: grid;
    grid-template-columns: 1fr repeat(2, 300px);
    grid-column-gap: 20px;
  }

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }

  .table {
    tr:first-child {
      width: 15px;
    }
  }
}
</style>