export const SusBatch = {
    BATCH_STATUS_TO_SENT: 'A enviar', // Automatic
    BATCH_STATUS_SENT: 'Enviado', // Manual
    BATCH_STATUS_PAYED: 'Pago', // Manual
    BATCH_STATUS_GLOSSED: 'Glosado', // Manual
    BATCH_STATUS_FINISHED: 'Finalizado', // Manual
    BATCH_STATUS_RESENT: 'Reenviado', // Manual
}

export const SusBatchType = {
    BATCH_TYPE_APAC: 'App\\Models\\Sus\\ApacGuide',
    BATCH_TYPE_BPA: 'App\\Models\\Sus\\BpaGuide',
    BATCH_TYPE_AIH: 'App\\Models\\Sus\\AihGuide'
}

export const SusGuide = {
    GUIDE_SITUATION_IN_PREPARATION: 'Em elaboração', // Manual
    GUIDE_SITUATION_WAITING_AUTHORIZATION: 'Aguardando autorização', // Manual
    GUIDE_SITUATION_AUTHORIZED: 'Autorizada', // Manual
    GUIDE_SITUATION_DENIED: 'Negada', // Manual
    GUIDE_SITUATION_CANCELED: 'Cancelada', // Manual
    GUIDE_SITUATION_SENT: 'Enviado', // Automatic
    GUIDE_SITUATION_OPEN: 'Em aberto', // Automatic
    GUIDE_SITUATION_PAYED: 'Pago', // Automatic
    GUIDE_SITUATION_PARTIALLY_PAID: 'Pago parcialmente', // Automatic
    GUIDE_SITUATION_GLOSSED: 'Glosado', // Automatic
    GUIDE_SITUATION_FINISHED: 'Finalizada'
}

export const TissGuideItem = {
    STATUS_ITEM_OPEN: 'Em aberto', // Manual
    STATUS_ITEM_PAID: 'Pago', // Manual
    STATUS_ITEM_PARTIALLY_PAID: 'Pago parcialmente', // Manual
    STATUS_ITEM_GLOSSED: 'Glosado', // Manual
}

export const SusBatchStatusOptions = [
    { label: 'A enviar', value: SusBatch.BATCH_STATUS_TO_SENT, hidden: true },
    { label: 'Enviado', value: SusBatch.BATCH_STATUS_SENT, _display: [
        SusBatch.BATCH_STATUS_PAYED,
        SusBatch.BATCH_STATUS_GLOSSED,
    ]},
    { label: 'Pago', value: SusBatch.BATCH_STATUS_PAYED, _display: [
        SusBatch.BATCH_STATUS_SENT,
        SusBatch.BATCH_STATUS_GLOSSED,
        SusBatch.BATCH_STATUS_RESENT,
    ]},
    { label: 'Glosado', value: SusBatch.BATCH_STATUS_GLOSSED, _display: [
        SusBatch.BATCH_STATUS_SENT,
        SusBatch.BATCH_STATUS_PAYED,
        SusBatch.BATCH_STATUS_RESENT,
    ]},
    { label: 'Reenviado', value: SusBatch.BATCH_STATUS_RESENT, _display: [
        SusBatch.BATCH_STATUS_GLOSSED,
    ] },
    { label: 'Finalizado', value: SusBatch.BATCH_STATUS_FINISHED, hidden: true  },
]

export const guideStatusOptions = [
    { label: 'Selecionar...', value: null },
    { label: 'Em aberto', value: TissGuideItem.STATUS_ITEM_OPEN },
    { label: 'Pago', value: TissGuideItem.STATUS_ITEM_PAID},
    { label: 'Pago parcial', value: TissGuideItem.STATUS_ITEM_PARTIALLY_PAID },
    { label: 'Glosado', value: TissGuideItem.STATUS_ITEM_GLOSSED },
]

export const guideModalSituationOptions = [     
    { label: 'Em elaboração', value: SusGuide.GUIDE_SITUATION_IN_PREPARATION },
    { label: 'Aguardando autorização', value: SusGuide.GUIDE_SITUATION_WAITING_AUTHORIZATION },
    { label: 'Autorizada', value: SusGuide.GUIDE_SITUATION_AUTHORIZED },
    { label: 'Negada', value: SusGuide.GUIDE_SITUATION_DENIED },
    { label: 'Cancelada', value: SusGuide.GUIDE_SITUATION_CANCELED },
    { label: 'Enviado', value: SusGuide.GUIDE_SITUATION_SENT, hidden: true },
    { label: 'Em aberto', value: SusGuide.GUIDE_SITUATION_OPEN, hidden: true },
    { label: 'Pago', value: SusGuide.GUIDE_SITUATION_PAYED, hidden: true },
    { label: 'Pago parcialmente', value: SusGuide.GUIDE_SITUATION_PARTIALLY_PAID, hidden: true },
    { label: 'Glosado', value: SusGuide.GUIDE_SITUATION_GLOSSED, hidden: true },
]